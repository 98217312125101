import {toast} from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import ProductBoxPlot from "./ProductBoxPlot";

const apiUrl = process.env.REACT_APP_API_URL;

const Products = ({owner}) => {
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [date, setDate] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [isSorted, setIsSorted] = useState(false);

    const importantOwners = ['tmcode', 'dmowskiego'];

    const fetchItems = async () => {
        try {
            const response = await axios.get(`${apiUrl}/products/${owner}`);
            const json = await response.data;
            const items = json.items;
            const date = json.date;
            setItems(items);
            setDate(date);
        } catch (error) {
            console.error('Error fetching offers:', error);
        }
    };

    const handleCheckboxChange = async (id, isChecked) => {
        try {
            const url = `${apiUrl}/products/${id}/${isChecked ? 'add' : 'remove'}/${owner}`;
            const response = await axios.put(url);

            if (response.status !== 200) {
                throw new Error(`API call failed with status ${response.status}`);
            }

            const updatedItem = response.data;
            const updatedItems = items.map((o) =>
                o.id === id ? updatedItem : o
            );
            setItems(updatedItems);

        } catch (error) {
            if (error.response && error.response.status === 400) {
                toast.error("Produkt już jest niedostępny");
                const updatedItems = items.filter((o) => o.id !== id);
                setItems(updatedItems);
            } else {
                console.error(`Error updating product status:`, error);
                toast.error("An error occurred while updating the product status.");
            }
        }
    };

    const sortByDiscount = (itemsToSort) => {
        return [...itemsToSort].sort((a, b) => {
            const discountA = (a.normal_price - a.price) / a.normal_price;
            const discountB = (b.normal_price - b.price) / b.normal_price;

            if (sortOrder === 'asc') {
                return discountA - discountB;
            } else {
                return discountB - discountA;
            }
        });
    };

    const handleSortByDiscount = () => {
        const sortedItems = sortByDiscount(filteredItems);
        setFilteredItems(sortedItems);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        setIsSorted(true);
    };

    const handlePriorityChange = async (id, newPriority) => {
        try {
            const url = `${apiUrl}/products/${id}/priority/${newPriority}`;
            const response = await axios.put(url);

            if (response.status !== 200) {
                throw new Error(`API call failed with status ${response.status}`);
            }

            const updatedItem = response.data;
            const updatedItems = items.map((o) => (o.id === id ? updatedItem : o));
            setItems(updatedItems);
        } catch (error) {
            console.error(`Error updating priority:`, error);
        }
    };

    const handlePriorityInputChange = (id, value) => {
        handlePriorityChange(id, value);
    };

    const handlePriorityInputBlur = (id, value) => {
        handlePriorityChange(id, value);
    };

    const handlePriorityInputKeyPress = (e, id, value) => {
        if (e.key === 'Enter') {
            handlePriorityChange(id, value);
        }
    };

    const handleDoubleClick = (hu) => {
    const url = `https://www.oleole.pl/web-cached/individual-outlet-offer-account-preview.ltr?huCode=${hu}`;
    navigator.clipboard.writeText(url);
  };

    useEffect(() => {
        fetchItems();
    }, []);

    useEffect(() => {
        let filtered = [...items];

        if (minPrice !== '') {
            filtered = filtered.filter(item => item.price >= parseFloat(minPrice));
        }

        if (maxPrice !== '') {
            filtered = filtered.filter(item => item.price <= parseFloat(maxPrice));
        }

        // Jeśli było sortowanie, zastosuj je ponownie
        if (isSorted) {
            filtered = sortByDiscount(filtered);
        }

        setFilteredItems(filtered);
    }, [items, minPrice, maxPrice, sortOrder, isSorted]);

    return (
        <div>
            <h1>Products</h1>
            <div className="mb-4 flex gap-4 items-center">
                <button onClick={handleSortByDiscount}>
                    Sort by Discount {sortOrder === 'asc' ? '▲' : '▼'}
                </button>
                <div className="flex gap-2 items-center">
                    <input
                        type="number"
                        placeholder="Min Price"
                        value={minPrice}
                        onChange={(e) => setMinPrice(e.target.value)}
                        className="w-24 px-2 py-1 border rounded"
                    />
                    <span>-</span>
                    <input
                        type="number"
                        placeholder="Max Price"
                        value={maxPrice}
                        onChange={(e) => setMaxPrice(e.target.value)}
                        className="w-24 px-2 py-1 border rounded"
                    />
                </div>
            </div>
            <div>
                Last update: {date}
            </div>
            <table className="table table-striped">
                <thead>
                <tr>
                    {importantOwners.includes(owner) && <th>HU</th>}
                    {importantOwners.includes(owner) && <th>plu</th>}
                    <th>Name</th>
                    <th>Group</th>
                    <th>Price</th>
                    <th>Normal Price</th>
                    <th>Discount</th>
                    <th>Description</th>
                    {importantOwners.includes(owner) && <th>Allegro</th>}
                    <th>Owner</th>
                    <th>Added</th>
                    <th>Priority</th>
                </tr>
                </thead>
                <tbody>
                {filteredItems.map((item) => (
                    <tr key={item.id}>
                        {importantOwners.includes(owner) && (
                            <th onDoubleClick={() => handleDoubleClick(item.id)} className="cursor-pointer">
                                {item.id}
                            </th>
                        )}
                        {importantOwners.includes(owner) && <th>{item.plu}</th>}
                        <td>{item.name}</td>
                        <td>{item.group}</td>
                        <td>{item.price}</td>
                        <td>{item.normal_price}</td>
                        <td>{((item.normal_price - item.price) / item.normal_price) * 100}</td>
                        <td>{item.description}</td>
                        {importantOwners.includes(owner) && <td>
                            {item.allegro.productPopularity && (
                                <>
                                    <div>{item.allegro.productPopularity?.replace(' osób kupiło ostatnio', '') || 'Brak danych'}</div>
                                    <div>
                                        <i
                                            data-tooltip-id={`tooltip-${item.id}`}
                                            data-tooltip-html={`<div>${item.allegro.description.replace(/\n/g, '<br>')}</div>`}
                                            className="fa fa-info-circle cursor-pointer"
                                        ></i>
                                        <Tooltip id={`tooltip-${item.id}`} place="top"/>
                                        <i
                                            data-tooltip-id={`tooltip-2-${item.id}`}
                                            className="fa fa-chart-bar cursor-pointer"
                                        ></i>
                                        <Tooltip id={`tooltip-2-${item.id}`} place="top" clickable>
                                            <ProductBoxPlot hu={item.id}/>
                                        </Tooltip>
                                    </div>
                                </>
                            )}
                        </td>}
                        <td>{item.owner}</td>
                        <td>
                            <input
                                type="checkbox"
                                checked={item.owner === owner}
                                onChange={(e) => handleCheckboxChange(item.id, e.target.checked)}
                            />
                        </td>
                        <td>
                            <input
                                type="number"
                                value={item.priority}
                                onChange={(e) => handlePriorityInputChange(item.id, e.target.value)}
                                onBlur={(e) => handlePriorityInputBlur(item.id, e.target.value)}
                                onKeyPress={(e) => handlePriorityInputKeyPress(e, item.id, e.target.value)}
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default Products;